<template>
  <div class="scoring">
    <div class="lives"><div class="life" v-for="life in lives"></div></div>
    <div class="total-score">{{ score }}</div>
    <!-- <div class="score">{{ breakEjectDir }}</div> -->

    <div class="score-accumelation">
      
      <div v-for="(score, j) in scoreGreen" :key="j" class="scores green">
        <transition name="fade" @after-enter="scoreAfterEnter(i, true)">
          <div v-if="score > 0">+{{ score }}</div>
        </transition>
      </div>
  
    </div>

    <div class="pits">
      <div class="pit red"></div>
      <div class="pit green"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "scoring",
  props: ["tiles", "lives", "score", "scoreGreen"],
  components: {},
  data: function () {
    return {};
  },
  computed: {},
  methods: {
    scoreAfterEnter: function (index, color) {
      console.log("la");
      if (color) {
        this.colorGreen[index] = 0;
      }
      // this.tiles[index].score = 0;
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.total-score {
  position: absolute;
  left: 50%;
  top: 20px;
  color: white;
  font-size: 5rem;
}
.pits {
  position: fixed;
  bottom: 0;
  // height: 100px;
  width: 100%;
  display: flex;
  .pit {
    width: 100%;
    height: 16px;
    transition: 0.3s;
    &.green {
      background: greenyellow;
      box-shadow: 0px -14px 80px greenyellow;
    }
    &.red {
      background: red;
      box-shadow: 0px -14px 80px red;
    }
  }
}
.score-accumelation {
  position: absolute;
  left: 0;
  width: 100vw;
  top: 43%;
  color: white;
  // transform: translateX(50%);
  font-size: 5rem;
  padding: 20px;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  pointer-events: none !important;
  .scores {
    position: absolute;
    right: 10%;
  }
}

.lives {
  position: absolute;
  left: 7%;
  top: 3%;
  display: flex;
  .life {
    background: red;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 10px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: 1.5s;
  transform: translateX(0) rotate(40deg);
  opacity: 1;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(-20%) rotate(40deg);
  opacity: 0;
}
</style>