<template>
  <div class="main">
    <canvas id="granim-canvas"></canvas>
    <canvas id="granim-choppers"></canvas>
    <Scoring
      :tiles="tiles"
      :score="score"
      :scoreGreen="scoreGreen"
      :lives="lives"
    />
    <!-- <GameOverDialog v-if="isGameOver"
      :isGameOver="isGameOver"

    /> -->

    <button class="controls-overlay" @click.stop.prevent="pressed()"></button>

    <button class="reset" @click="reset">↺</button>
    <a-scene physics="debug: false">
      <a-assets>
        <audio ref="bing" id="bing" src="audio/bing.mp3"></audio>
        <audio ref="break" id="break" src="audio/break.mp3"></audio>
        <audio
          :ref="`sound`"
          :id="`sound${n}`"
          v-for="n in 5"
          :key="n"
          :src="`audio/seq/${n}.mp3`"
          crossorigin="anonymous"
        ></audio>
      </a-assets>
      <a-entity
        ref="trackWrap"
        class="track-wrap"
        position="0 -6 -20"
        :rotation="`0 0 0`"
        :animation="`startEvents: shake-stack; property: position; from: -0.1 -6 -20; to: 0 -6 -20; dur: 100; loop: 5; dir: alternate`"
        :animation__over-rot="`startEvents: game-over; property: rotation; to: 90 0 0; dur: 100;`"
        :animation__over-pos="`startEvents: game-over; position: rotation; to: 0 -6 -25; dur: 100;`"
      >
        <Choppers
          :choppersHeight="choppersHeight"
          :choppersPosition="choppersPosition"
          ref="choppers"
        />
        <!-- :animation="`property: rotation.y; to: 80; dur: 8000; dir: alternate; loop: true;`" -->
        <a-box
          class="floor"
          :position="`0 -1 0`"
          :rotation="`0 0 0`"
          height="1"
          depth="6"
          width="6"
          color=""
        >
        </a-box>
        <a-entity
          :class="'tileWrap' + i"
          v-for="(tile, i) in tiles"
          :key="i"
          :position="`0 0 0`"
          :rotation="`0 0 0`"
          :animation="`pauseEvents: stop-movement${i} ;property: position.x; from: 10; to: -10; dur: 2000; dir: alternate; loop: true;`"
        >
          <!-- :animation__gameover="`startEvents: start-drop${i} ;property: scale; to:0 0 0; dur: 500;`"
          :animation__gameover_shake="`startEvents: start-drop${i} ;property: rotation; to:60 60 30; dur: 10; alternate; loop: true;`" -->
          <!-- :sound="`src: url(/audio/seq/${audioIndex}.mp3); autoplay: true`" -->

          <a-box
            :position="`0 ${i} 0`"
            height="1"
            depth="6"
            :width="mainTileWidth"
            v-if="!tile.fell && !isGameOver"
            material=" src: #granim-canvas;"
            :animation__rotate="`startEvents: eject_right${i}; property: rotation; to: 40 0 0; dur: 100; `"
            canvas-updater
            @collide="hitCatcher($event, i)"
          >
          </a-box>
          <!-- :animation="`startEvents: eject_left${i}; property: position; to: -30 ${i} 0; dur: 500; `"
            :animation__right="`startEvents: eject_right${i}; property: position; to: 30 ${i} 0; dur: 500; `" -->
          <a-box
            :ref="`boxSec`"
            :position="`0 ${i} 0`"
            height="1"
            depth="6"
            width="0"
            material=" src: #granim-canvas;"
            :animation__rotate="`startEvents: minimize${i}; property: scale; to: 0 0 0; dur: 100; `"
          >
          </a-box>
        </a-entity>
      </a-entity>

      <!-- aabb-collider="objects: .collide; collideNonVisible: false; debug: true" -->

      <!-- <TrailParticles position="0 4.2 3" rotation="15 0 0" :trailDirection="trailDirection"/> -->

      <a-entity
        id="rig"
        ref="rig"
        :position="`0 0 -15`"
        :animation="`property: position; to: 0 ${choppersHeight} -15; dur: 500; `"
      >
        <a-entity
          id="camera"
          :camera="`fov:${isMobile ? 100 : 80}; spectate: true`"
          :position="`0 0 10`"
          rotation="-20 0 0"
        >
        </a-entity>
        <a-plane
          visible="false"
          static-body
          width="250"
          height="250"
          rotation="-90 0 0"
          :position="`0 -40 0`"
          class="collider-catcher"
        >
        </a-plane>
      </a-entity>

      <!-- <a-sky color="#ECECEC"></a-sky> -->
    </a-scene>
  </div>
</template>

<script>
import Choppers from "../components/stack/Choppers";
import GameOverDialog from "../components/stack/GameOverDialog";
import Scoring from "../components/stack/Scoring";
import Granim from "granim";
import * as Tone from "tone";

export default {
  name: "Main",
  components: { Choppers, Scoring, GameOverDialog },
  data: function () {
    return {
      isMobile: false,
      isPressing: false,
      isGameOver: false,
      scoreDir: true,
      breakEjectDir: true,
      stackerOrSlider: false, // dictates stacker or slider mode
      tiles: [{ broken: false, fell: false }],
      lives: 3,
      scoresRight: [],
      scoreGreen: [],
      scoreRed: [],
      mainTileWidth: 6,
      audioIndex: 0,
      score: 0,
      music: [
        [
          "D4",
          "F4",
          "A4",
          "C4",
          "E4",
          "D4",
          "F4",
          "A4",
          "C4",
          "E4",
          "D4",
          "F4",
          "A4",
          "C4",
          "E4",
          "D4",
          "F4",
          "A4",
          "C4",
          "E4",
        ], // Cm (i)
      ],
      // synth: new Tone.Synth().toDestination(),
    };
  },
  computed: {
    choppersHeight: function (e) {
      return this.tiles.length;
    },
    choppersPosition: function (e) {
      return this.choppersHeight / 2 - 1.4;
    },
  },
  watch: {
    lives: function (val) {
      if (this.lives === 0) {
        this.gameOver();
      }
    },
  },
  methods: {
    reset: function (e) {
      console.log("res");
      window.location.reload();
    },
    hitCatcher: function (e, index) {
      this.destroyObject(index);
    },
    destroyObject: function (index) {
      setTimeout(() => {
        this.$nextTick(() => {
          this.tiles[index].fell = true;
        });
      }, 2000);
    },
    gameOver: function (index) {
      console.log("over game");
      this.$refs.trackWrap.emit("game-over");
      this.$refs.boxSec[this.tiles.length-1].emit("minimize" + this.tiles[this.tiles.length]);
      this.isGameOver = true;
    },
    pressed: function () {
      let index = this.tiles.length - 1;
      let mainTileWidth = this.mainTileWidth;
      let tileWrap = document.querySelector(".tileWrap" + index);
      let tilePosition = tileWrap.object3D.position;
      let tileWidth = Math.abs(tileWrap.object3D.position.x);
      let placementTile = tileWrap.children[0];
      let breakAwayTile = tileWrap.children[1];
      this.playNote();
      this.$refs.choppers.$el.emit("chop");

      tileWrap.emit("stop-movement" + index);
      if (mainTileWidth > tileWidth) {
        let breakAwayTileWidth = mainTileWidth - tileWidth;
        window.navigator.vibrate(70);
        this.tiles[index].broken = true;

        placementTile.setAttribute("width", tileWidth);
        breakAwayTile.setAttribute("width", breakAwayTileWidth);

        let placementSize = this.breakToSize(mainTileWidth, tileWidth);
        let breakAwaySize = this.breakToSize(mainTileWidth, breakAwayTileWidth);

        this.breakEjectDir = tilePosition.x > 0 ? true : false;

        placementTile.object3D.position.x =
          tilePosition.x > 0 ? placementSize : -placementSize;

        breakAwayTile.object3D.position.x =
          tilePosition.x > 0 ? -breakAwaySize : breakAwaySize;

        this.tiles.push({ broken: false, fell: false, score: 0 });

        placementTile.emit(
          `eject_${this.breakEjectDir ? "right" : "right"}${index}`
        );
        // placementTile.setAttribute(
        //   "placementTile",
        //   `mass: 8; linearDamping: 0.001;`
        // );
        placementTile.setAttribute(
          "animation__pos",
          `property: position; to: ${this.breakEjectDir ? "15" : "-15"} ${
            index - 45
          } 0; dur: 1000;`
        );
        placementTile.setAttribute(
          "animation__rot",
          `property: rotation; to: 0 0 ${
            this.breakEjectDir ? "90" : "-90"
          }; dur: 1000;`
        );
        this.destroyObject(index);
        this.$refs.rig.setAttribute(
          "animation",
          `property: position; to: 0 ${index} -15; dur: 200;`
        );

        // this.mainTileWidth = tileWidth
        this.scoreHandler(breakAwayTileWidth, index);
        placementTile.emit("start-drop" + index);
      } else {
        this.gameOver();
      }
    },
    scoreHandler: function (scoreForCalc, index) {
      let direction = this.breakEjectDir;
      let scoreDir = this.scoreDir;
      let scoreCalc = Math.floor(scoreForCalc); // takes broken tile width for score calculation
      // this.scoresRight.push(scoreCalc);
      if (direction === scoreDir) {
        this.score += scoreCalc;
        this.scoreGreen.push(scoreCalc);
        setTimeout(() => {
          this.scoreGreen[this.scoreGreen.length - 1] = 0;
        }, 700);
      } else {
        this.lives--;
        this.$refs.trackWrap.emit("shake-stack");
        window.navigator.vibrate(100);
      }
      // this.tiles[index].score = scoreCalc;
    },

    playNote: function () {
      var index = this.audioIndex;
      // var sounds = this.$refs.sound;
      const synth = new Tone.Synth().toDestination();
      const now = Tone.now();
      synth.triggerAttackRelease(this.music[0][index], "8n", now);
      this.audioIndex++;
      // sounds[index].load();
      // sounds[index].play();
      // console.log(index);
      // console.log(sounds.length);
      // if (index === this.music[0].length - 1) this.audioIndex = 1;
      // else this.audioIndex++;
    },

    breakToSize: function (x, y) {
      return x / 2 - y / 2;
    },
  },
  mounted() {
    if (AFRAME.utils.device.isMobile()) {
      this.isMobile = true;
    }
    // const synth = new Tone.Synth().toDestination();

    var granimInstance = new Granim({
      element: "#granim-canvas",
      name: "granim",
      states: {
        "default-state": {
          direction: "top-bottom",
          transitionSpeed: 2500,
          gradients: [
            ["#ff9966", "#ff5e62"],
            ["#00F260", "#0575E6"],
            ["#e1eec3", "#f05053"],
          ],
        },
      },
    });
    var granimInstance = new Granim({
      element: "#granim-choppers",
      name: "granim",
      states: {
        "default-state": {
          direction: "bottom-top",
          transitionSpeed: 500,
          gradients: [
            ["#F5F7FA", "#B8C6DB"],
            ["#B8C6DB", "#F5F7FA"],
          ],
        },
      },
    });
    AFRAME.registerComponent("canvas-updater", {
      dependencies: ["geometry", "material"],

      tick: function () {
        var el = this.el;
        var material;

        material = el.getObject3D("mesh").material;
        if (!material.map) {
          return;
        }
        material.map.needsUpdate = true;
      },
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$background: #202124;
$border: #5f6368;
$surface: #1f1f1f;
$primaryTwo: #3700b3;
$primary: #bb86fc;
$primaryHover: #bb86fc8c;
#granim-canvas,
#granim-choppers {
  position: absolute;
  width: 1px;
  height: 1px;
}
.controls-overlay {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  display: flex;
  opacity: 0;
}
.reset {
  position: absolute;
  right: 0;
  background: #107249;
  font-size: 2rem;
  padding: 20px;
  z-index: 5;
}
.overlay-debug {
  position: absolute;
  background: rgb(72, 255, 0);
  font-size: 2rem;
  padding: 20px;
  z-index: 5;
}
.stop {
  width: 400px;
  height: 100px;
}
.main-menu {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: yellow;
  font-size: 2rem;
  padding: 20px;
  z-index: 5;
}
.popup {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: yellow;
  font-size: 2rem;
  padding: 20px;
  z-index: 5;
}
</style>