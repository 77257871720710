<template>
  <div id="app">
    <Main />
  </div>
</template>

<script>
import Main from "./components/Stack.vue";

export default {
  name: "App",
  components: {
    Main,
  },
};
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  background-color: #d387ab;
  background-image: linear-gradient(315deg, #d387ab 0%, #b279a7 74%);
}
</style>
