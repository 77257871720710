import Vue from "vue";
import App from "./App.vue";

Vue.config.ignoredElements = [
  "a-scene",
  "a-sky",
  "a-entity",
  "a-plane",
  "a-text",
  "a-sphere",
  "a-gltf-model",
  "a-box",
  "a-cylinder",
  "a-assets",
  "a-triangle",
];

Vue.config.productionTip = true;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
