<template>
  <a-entity
    
    class="choppers"
    position="0 -0.3 0"
    :animation__up="`startEvents: chop ;property: position.y; to: 1; dur: 200; dir: alternate`"
    :animation__down="`startEvents: chop ;property: position.y; to: -0.3; delay: 300; dur: 200; dir: alternate`"
  >
    <!-- <a-box
      class="pusher"
      width="0.5"
      rotation="0 0 -30"
      :position="`2.8 ${choppersHeight - 4.3} 0`"
      static-body
    ></a-box>
    <a-box
      class="pusher"
      width="0.5"
      rotation=" 0 0 30"
      :position="`-2.8 ${choppersHeight - 4.3} 0`"
      static-body
    ></a-box> -->
    <a-entity class="choppers-wrap">
      <a-plane
        :position="`-3 ${choppersPosition} 0`"
        rotation="0 90 0"
        :height="choppersHeight"
        width="6"
        material=" src: #granim-choppers;"
        canvas-updater
      >
        <a-triangle
          v-for="n in 6"
          :key="n"
          scale="1 1 1"
          :position="`${-3.5 + n} ${choppersPosition + 1.4} 0`"
          material=" src: #granim-choppers;"
        ></a-triangle>
      </a-plane>
      <a-plane
        :position="`3 ${choppersPosition} 0`"
        rotation="0 -90 0"
        :height="choppersHeight"
        width="6"
        material=" src: #granim-choppers;"
        canvas-updater
      >
        <a-triangle
          v-for="n in 6"
          :key="n"
          scale="1 1 1"
          :position="`${-3.5 + n} ${choppersPosition + 1.4} 0`"
          material=" src: #granim-choppers;"
        ></a-triangle>
      </a-plane>
    </a-entity>
  </a-entity>
</template>

<script>
export default {
  name: "Choppers",
  props: ["choppersHeight", "choppersPosition"],
  components: {},
  data: function () {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>