<template>
  <div class="game-over-dialog dialog"></div>
</template>

<script>
export default {
  name: "GameOverDialog",
  props: ['isGameOver'],
  components: {},
  data: function () {
    return {};
  },
  computed: {},
  methods: {
    scoreAfterEnter: function (index, color) {},
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dialog {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 80vw;
  max-width: 300px;
  height: 220px;
  background: yellowgreen;
  z-index: 6;
}
</style>